body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  font-family: 'Roboto', sans-serif;
}
a {
  text-decoration: none;
}
#details{
  margin-top: 100px;
  text-align: center;
  padding: 0 100px;
  line-height: 2;
}
#details a{
  color: #002e5b;
  text-decoration: none;
  font-size: 18px;
}

body {
  margin: 0px;
}
.name {
  margin: 30px;
}
#mobileNav {
  display: none;
}
#topContact{
  text-align: center;
  background-color: white;
  padding: 20px;
  position: absolute;
  border-radius: 5px;
  width: 100%;
}
#topContact a{
  color: darkgoldenrod;
  text-decoration: none;
  font-size: 16px;
  margin-right: 20px;
}


#nav,
#sticky {
  font-size: 22px;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 2;
  width: 100%;
  margin-top: 80px;
  z-index: 10;
  transition: all linear 500ms;
  display: flex;
  color: white;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
}
#sticky{
  position: fixed;
  background-color: white;
  border-radius: 2px;
  color: darkgoldenrod;
  margin-top: 0;
  box-shadow: 1px 1px 5px 1px darkgoldenrod;

}
#nav ul li a,
#sticky ul li a{
  font-size: 24px;
}

#nav li,
#sticky li {
  display: inline-block;
  margin-right: 2.504vw;
  text-decoration: underline;
}

#nav li a,
#sticky li a {
  text-decoration: none;
  font-size: 20px;
  transition: all 300ms linear 0s;
  margin: 0px auto;
  color: white;
}
#sticky li a{
  color: darkgoldenrod;

}

#nav li a:hover,
#sticky li a:hover {
  text-decoration: underline;
  text-decoration-color: bisque;
}

#nav li a:active,
#sticky li a:active {
  text-decoration: underline;
  text-decoration-color: bisque;
}

#nav #dropdwon li a{
  color: darkgoldenrod;
} 
#dropdown{
  margin-top: 10px;

}


.banner {
 /* background: linear-gradient(14deg, #ffffff, #745308);*/
 background-image:url('./Images/Pictures/IMG_5452banner.jpg');
  animation: gradientAnimation 40s ease-in-out infinite;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 1024px;
  margin: 0px 0px 0px 0px;
}

@keyframes gradientAnimation {
  0%{background-position:100% 100%}
  50%{background-position:100% 95%}
  100%{background-position:100% 90%}
}

.banner h1 {
  font-size: 28px;
  color: white;
  position: relative;
  text-align: center;
  top: 50%;
  cursor: text;
}
.banner p {
  font-size: 26px;
  color: white;
  position: relative;
  text-align: center;
  top: 50%;
  cursor: text;
}



#join{
  text-align: center;
  padding: 20px 0px;
  border-top: 1px solid;
}
#join iframe{
  width: 1000px;
  height: 720px;
  overflow: hidden;
}

.about {

  position: relative;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 100px;
  padding: 20px;
  }
  .about iframe {
    margin: auto;
  }
  
  .aboutText{
    display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 17px;
  grid-row-gap: 8px;
  }
  .about p{
    font-size: 16px;
  }

.service {
  padding: 100px;
}
.service ul {
  list-style: none;
  line-height: 30px;
}
.service p {
  font-size: 16px;
}
.service span{
  color: darkgoldenrod;
}
.service h1{
  text-align: center;
}


/*
.contact {
  color: darkgoldenrod;
  background-color:#f5f5f5;
  height: auto;
  padding-bottom: 30px;
  padding-top: 10px;
}
.contact h1{
  color: darkgoldenrod;
  text-align: center;
  font-size: 32px;
}
.contact h2 {
  color: black;
  cursor: text;
}
.contact a {
  padding: 20px 20px;
  font-size: 16px;
  color: darkgoldenrod;
  transition: all 200ms linear 0s;
  line-height: 2;
}
.contact a:hover {
  color: darkgoldenrod;
}
.contact a:active {
  color: darkgoldenrod;
}

.contact a h2:hover {
  color: black;
  transition: all 200ms linear 0s;
}
.contact a h2:active {
  color: black;
  transition: all 200ms linear 0s;
}
.contact img {
  padding: 10px;
}
*/

#contact{
  display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: 1fr;
grid-column-gap: 50px;
grid-row-gap: 0px;
margin: 50px 50px;
}



#contactInfo{
  background-color:#b8870b41;
  border-radius: 10px;
  padding: 50px 5.208vw;
  margin-right: 7.813vw;
  margin-bottom: 1vw;
  
}
#contactInfo h2{
}
#contactInfo p{
  font-size: 16px;
  line-height: 1.75;
}

#contactInfo a{
  text-decoration: none;
  color: black;
  font-size: 18px;
  line-height: 3;
  font-weight: bold;
}

#contactInfo i{
  font-size: 24px;
}


#form textarea{
  border: none;
  border-bottom: #b8870b57 3px solid;
  border-radius: 0px;
  font-size: 16px;

}
#form{
  padding: 50px 0px;
}


#form input{
  border: none;
  border-bottom: #e1e8ef 3px solid;
  border-radius: 0px;
  font-size: 18px;
}



#form label{
  color: #00000077;
}


#events{
  text-align: center;
}

.event{
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 15px;
  padding: 60px;
  text-align: center;
}
.eventHeader{
  color: darkgoldenrod;
}
.eventMeta p{
  padding: 20px;
  display: inline;
  color: gray;
  font-weight: lighter;
}
.shortcut{
  margin-top: 40px;
  padding: 20px;

}
.shortcut h1{
  color: darkgoldenrod;
  text-align: center;
}

.Card{
  border: none !important;
  box-shadow: none !important;
  
}

.card-group{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 0px;
}


.upcomingEvent{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 500px;
  grid-row-gap: 0px;
  padding: 100px;
  animation: gradientAnimation 35s ease infinite;
  border-radius: 5px;
}

.upcomingEvent h2{
  color: darkgoldenrod;
  display: inline;
}

.side{
  padding-top: 10px;
}
.side p{
  color: black;
}
.upcomingEvent p{
}

.button-1{
  font-size: 20px;
  color: darkgoldenrod;
  position: relative;
  text-align: center;
  top: 50%;
  padding: 5px 15px;
  text-decoration: none;
  border: 1px solid darkgoldenrod;
  background-color: #e1e8ef;
  border-radius: 4px;
  font-weight: 800;
  transition: all 400ms linear 0s;
  cursor: pointer;
}

.button-1:hover {
  background-color: #e1e8ef;
  color: darkgoldenrod;
  font-size: 18px;
}
.button-1:active {
  background-color: #e1e8ef;
  color: darkgoldenrod;
  font-size: 22px;
}

.button-2{
  color: black;
  background-color: #b8870b41;
  border: 1px solid #b8870b41;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  transition: all 400ms ease-in-out 0s;
}

.button-2:hover {
  padding: 8px;
}

.services {
  text-align: center;
}

.services h2{
  color:darkgoldenrod;

}

.info{
 
}

#allServices{
  text-align: center;
}









/*mobile************************************************************************************************************************************/

@media only screen and (max-width: 1130px){
  html{
    overflow: hidden;
    scroll-behavior: smooth;
  }
  
  .pagecontent {
    margin: 0px;
    font-size: 14px;
  }
  .banner h1{
    text-align: center;
  }

  .banner p {
    font-size: 14px;
  }
  .banner a {
    font-size: 14px;
  }
  .banner a:hover {
    font-size: 12px;
  }
  .banner img {
    opacity: 0.5;
  }
  #join iframe{
    max-width: 400px;
    max-height: 260px;
  }

  .about {
    position: relative;
    overflow: hidden;
    display: block;
  }
  
  .aboutText{
    display: block;
  }
  
  .about iframe {
    text-align: center;
    align-items: center;
    padding-bottom: 15rem;
    overflow: hidden;
    position: relative;
    max-width: 500px;
    max-height: 400px;
    margin-right: 0px;
    padding-bottom: 50px;
    padding-left: 0px;
    padding-right: 0px;
  }
  #topContact{
    display: none;
    text-align: left;
  }

  #topContact a{
    margin-bottom: 5px;
    font-size: 12px;
  }

  #nav,
  #sticky {
    display: none;
  }
  #mobileNav{
    display: block;
    position: fixed;
    width: 100%;
    z-index: 5;
    color: black;
    top: 1;
    margin-bottom: 100px;
  }

  #menuItems{
    overflow: hidden;
    display: block;
    width: 100%;
    height: 100%;
    transition: all linear 200ms;
    transform: translateY(-800px);
    
  }
  #menuItems a{
    padding: 10px 0px;

  }
  
  #menuItems a {
    text-align: center;
    display: block;
    padding: 6px 0px;
    list-style: none;
    border-bottom: 1px solid;
    text-decoration: none;
    background-color: darkgoldenrod;
    color: white;
    transition: all linear 200ms;
  }
  #hamBg{
    text-align: right;
    transition: all linear 200ms;
    background-color: darkgoldenrod;
    width: 100%;
    height: 50px;
    padding: 10px 20px;
  
  }
  #menuButton{
    color: white;
    overflow: hidden;
    display: none;
    font-size: 30px;
    cursor: pointer;
    transition: all linear 200ms;
  }
  .mobileSticky{
    position: fixed;
    top: 0;
    transition: all linear 500ms;
    width: 100%;
    }

  #homebtn {
    float: left;
    padding-top: 10px;
    padding-left: 15px;
    text-decoration: none;
    color: darkgoldenrod;
    cursor: pointer;
  }
  #homebtn:active {
    color: black;
  }

  .about p {
    line-height: -2%;
    padding: 20px 10px;
    font-size: 14px;

  }
  .about h2 {
    text-align: center;
  }
  .about ul li {
    font-size:14px;
    text-align: left;
  }
  .about img {
    margin-left: 0%;
    margin-right: 0%;
  }
  .service {
    font-size: 14px;
    padding: 10px;
  }
  #service p{
    font-size: 14px;
  }

  #service h1{
    font-size: 20px;
  }

  .Card{
    border: none !important;
    box-shadow: none !important;
  }
  /*
  #contact {
    text-align:left;
    padding-bottom: 25px;
    
  }
  #contact h1{
    font-size: 20px;
  }
  #contact a {
    margin: 0px;
    padding-left: 10px;
    font-size: 14px;
  }
*/

#contact{
  display: block;
  margin: 0px;
}
#contactInfo{
  padding: 20px;
  margin: 50px 10px;
}
#contactInfo a{
  font-size: 16px;
}
#contactInfo p{
  font-size: 14px;
}
#contactInfo i{
  font-size: 16px;
}
#contactInfo h2{
  font-size: 20px;
}

#form{
  padding: 20px;
}

  .event{
    display: block;
  }
  .eventMeta p {
    display: block;
    padding: 10px;
  }

  .upcomingEvent{
    display: block;
    padding: 15px;
    padding-bottom: 50px;
  }

  .upcomingEvent h2{
    color: darkgoldenrod;
    display: inline;
    font-size: 16px;
  }
  
  .side{
    padding-top: 30px;
  }
  .upcomingEvent p{
    color: black;
    font-size: 14px;
  }
  .upcomingEvent h1{
    font-size: 18px;
  }
  .side{
    font-size: 12px;
  }
  .button-1{
    font-size: 16px;
  }
  .button-1:hover{
    font-size: 17px;
  }
  .button-1:active{
    font-size: 18px;
  }
  .card-group{
    display: block;
    padding-bottom: 100px;
  }
  .Card img{
    width: 150px;
  }
  .Card{
    padding-bottom: 50px;
  }



}
